// Dashboard Route
export const tradeTariffDasboardRoute = '/configuration/dashboard'

export const designationWiseUser = '/acid-license/application-process/user-list'

// Formalin Licnese Routes......
const formalinLicense = '/formalin-import-management/license-application/'
export const pendingList = formalinLicense + 'pending-list'
export const formalinLicenseForwardApi = formalinLicense + 'forward'
// verification
export const verificationList = formalinLicense + 'verification-list'
export const verificationStore = formalinLicense + 'verify-store'
// inspection
export const inspectionList = formalinLicense + 'inspection-list'
export const inspectionStore = formalinLicense + 'inspection-store'
// approve
export const approvalList = formalinLicense + 'approval-list'
export const approvedList = formalinLicense + 'approved-list'
export const approvalStore = formalinLicense + 'approval-store'
// rejected
export const rejectStore = formalinLicense + 'reject-store'
export const rejectedList = formalinLicense + 'rejected-list'

// Formalin clearance Routes......
const formalinClearance = '/formalin-import-management/license-clearance/'
export const cPendingList = formalinClearance + 'pending-list'
export const formalinClearanceForwardApi = formalinClearance + 'forward'
// verification
export const cVerificationList = formalinClearance + 'verification-list'
export const cVerificationStore = formalinClearance + 'verify-store'
// inspection
export const cInspectionList = formalinClearance + 'inspection-list'
export const cInspectionStore = formalinClearance + 'inspection-store'
// approve
export const cApprovalList = formalinClearance + 'approval-list'
export const cApprovedList = formalinClearance + 'approved-list'
export const cApprovalStore = formalinClearance + 'approval-store'
// rejected
export const cRejectStore = formalinClearance + 'reject-store'
export const cRejectedList = formalinClearance + 'rejected-list'
